import * as Msal from 'msal';
import {
  b2cPolicies, msalConfig, loginRequest,
} from './auth';

// -------------------------------------------------------------------------------------------------
// msal interaction functions
// -------------------------------------------------------------------------------------------------

// Use v1 for now.
export const msalObj = new Msal.UserAgentApplication(msalConfig);

export function authRedirectCallBack(error, response) {
  // Error handling
  if (error) {
    console.log(error);

    // Check for forgot password error
    // Learn more about AAD error codes at https://docs.microsoft.com/en-us/azure/active-directory/develop/reference-aadsts-error-codes
    if (error.errorMessage.indexOf('AADB2C90118') > -1) {
      try {
        // Password reset policy/authority
        msalObj.loginRedirect(b2cPolicies.authorities.forgotPassword);
      } catch (err) {
        console.log(err);
      }
    }
    return (false);
  }

  // We need to reject id tokens that were not issued with the default sign-in policy.
  // "acr" claim in the token tells us what policy is used (NOTE: for new policies (v2.0), use
  // "tfp" instead of "acr")
  // To learn more about b2c tokens, visit https://docs.microsoft.com/en-us/azure/active-directory-b2c/tokens-overview
  if (response.tokenType === 'id_token' && response.idToken.claims.acr === b2cPolicies.names.forgotPassword) {
    msalObj.logout();
    window.alert('Password has been reset successfully. \nPlease sign-in with your new password.');
  } else if (response.tokenType === 'id_token' && response.idToken.claims.acr === b2cPolicies.names.editProfile) {
    window.alert('Profile has been updated successfully.');
  } else if (response.tokenType === 'id_token' && response.idToken.claims.acr === b2cPolicies.names.signUpSignIn) {
    console.log(`id_token acquired at: ${new Date().toString()}`);
  } else if (response.tokenType === 'access_token') {
    console.log(`access_token acquired at: ${new Date().toString()}`);
    const { accessToken } = response;
    console.log('Request made to Web API:');
    if (accessToken) {
      try {
        console.log('tried calling callApiWithAccessToken');
        // callApiWithAccessToken(apiConfig.webApi, accessToken);
      } catch (err) {
        console.log(err);
      }
    }
  } else {
    console.log(`Token type is: ${response.tokenType}`);
  }
  return true;
}

export function signIn() {
  return msalObj.loginRedirect(loginRequest);
}

// sign-out the user
export function signOut() {
  // Removes all sessions, need to call AAD endpoint to do full logout
  msalObj.logout();
}

export function handleRedirect() {
  msalObj.handleRedirectCallback(authRedirectCallBack);
}
