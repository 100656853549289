import * as msal from 'msal';

import {
  B2C_SIGNUPSIGNIN,
  B2C_FORGOTPASSWORD,
  B2C_PROFILEEDIT,
  B2C_AUTHORITY,
  B2C_CLIENT_ID,
  B2C_REDIRECT_URL,
} from 'env-create-react-app';

export const b2cPolicies = {
  names: {
    signUpSignIn: B2C_SIGNUPSIGNIN,
    forgotPassword: B2C_FORGOTPASSWORD,
    editProfile: B2C_PROFILEEDIT,
  },
  authorities: {
    signUpSignIn: {
      authority: `${B2C_AUTHORITY}/${B2C_SIGNUPSIGNIN}`,
    },
    forgotPassword: {
      authority: `${B2C_AUTHORITY}/${B2C_FORGOTPASSWORD}`,
    },
    editProfile: {
      authority: `${B2C_AUTHORITY}/${B2C_PROFILEEDIT}`,
    },
  },
};

export const msalConfig = {
  auth: {
    clientId: B2C_CLIENT_ID,
    authority: `${B2C_AUTHORITY}/${B2C_SIGNUPSIGNIN}`,
    redirectUri: B2C_REDIRECT_URL,
    knownAuthorities: [
      `${B2C_AUTHORITY}/${B2C_SIGNUPSIGNIN}`,
    ],
    validateAuthority: false,
  },
  cache: {
    cacheLocation: 'sessionStorage', // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
  system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case msal.LogLevel.Error:
            console.error(message);
            return;
          case msal.LogLevel.Info:
            console.info(message);
            return;
          case msal.LogLevel.Verbose:
            console.debug(message);
            return;
          case msal.LogLevel.Warning:
            console.warn(message);
            return;
          default:
            console.log(message);
        }
      },
    },
  },
};

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const loginRequest = {
  scopes: ['openid', 'profile'],
};
export const tokenRequest = {
  scopes: [],
};
