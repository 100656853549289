import React, { useState } from 'react';
import { Container, Message, Icon } from 'semantic-ui-react';
import Cookies from 'js-cookie';
import { t } from 'i18n-js';
import { getDomain } from '../../utils/helpers';
import AnnouncementContent from './AnnouncementContent';
import AnnouncementCount from './AnnouncementCount';
import AnnouncementNav from './AnnouncementNav';

const Announcement = ({ announcements }) => {
  const [dismissed, setDismissed] = useState(Cookies.get('announcementDismissedTime'));
  const [index, setIndex] = useState(1);

  if (dismissed) {
    announcements = announcements.filter((a) => new Date(a.publishedAt).getTime() >= dismissed);
  }
  const total = announcements ? announcements.length : 0;

  const handleDismiss = () => {
    const now = new Date();
    const future = new Date();
    future.setDate(now.getDate() + 30);

    // js-cookie package was not setting the cookie, applying an
    // expired value rejected it, using pure javascript works
    console.log(getDomain());
    document.cookie = 'announcementDismissedTime=' + now.getTime()
    + ';expires=' + future.toUTCString() + ';path=/;domain=' + getDomain();
    setDismissed(now.getTime());
  };

  if (!total) {
    return <></>;
  }

  return (
    <div
      className="aridhia-alert"
      role="alert"
      aria-label={t('accessibility.announcement')}
    >
      <Container>
        <Message icon onDismiss={handleDismiss}>
          <Icon name="triangle exclamation" />
          <AnnouncementContent announcement={announcements[(index - 1)]} />
          { total > 1
            && <AnnouncementCount index={index} total={total} /> }
          <AnnouncementNav index={index} total={total} setIndex={setIndex} />
        </Message>
      </Container>
    </div>
  );
};

export default Announcement;
