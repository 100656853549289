import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  useLocation,
} from 'react-router-dom';

import {
  ENDPOINT_REQUEST_WORKSPACE,
} from 'env-create-react-app';

import Announcements from './components/Announcements/Announcements';
import Header from './components/Header';
import Content from './components/Content';
import RequestWorkspace from './components/RequestWorkspace';

import {
  msalObj,
  signIn,
  signOut,
  handleRedirect,
} from './auth/signin';

class App extends React.Component {
  constructor(props) {
    super(props);

    // this.pathname = useLocation();

    // Register Callbacks for Redirect flow
    handleRedirect(); // handles any redirect (like request password change)

    // eslint-disable-next-line no-restricted-globals
    history.scrollRestoration = 'manual'; // prevent autoscroll on page reload. Can sometimes prevent banners from showing.

    let loggedIn = false;
    let name = null;
    if (msalObj.getAccount()) {
      loggedIn = true;
      const account = msalObj.getAccount();

      if (typeof account.idToken === 'undefined' || account.idToken == null) {
        console.error('Failed to obtain idToken');
        console.error(account);
      } else {
        const givenName = account.idToken.given_name;
        const familyName = account.idToken.family_name;
        name = `${givenName} ${familyName}`;
      }
    } else {
      signIn(); // prompts the user to login
    }

    this.state = { loggedIn, name };
  }

  appSignOut() {
    signOut();
    this.setState({ loggedIn: false, name: null });
  }

  render() {
    const { loggedIn, name } = this.state;
    // only show page if a valid user is logged in
    if (loggedIn && name) {
      return (
        <>
          <Header
            loggedIn={loggedIn}
            name={name}
            signIn={signIn}
            signOut={() => this.appSignOut()}
          />
          <div id="announcement">
            <Announcements />
          </div>
          <div id="flex">
            <Router>
              <AppContentRouter />
            </Router>
          </div>
        </>
      );
    }
    // else return redirecting to login page
    return (
      <div>Redirecting to login...</div>
    );
  }
}

const AppContentRouter = () => {
  const { pathname } = useLocation();

  return (
    <Switch>
      <Redirect from="/:url*(/+)" to={pathname.slice(0, -1)} />
      <Redirect from="/:url*(.html+)" to={pathname.slice(0, -5)} />
      <Route exact path={ENDPOINT_REQUEST_WORKSPACE}>
        <RequestWorkspace />
      </Route>
      <Route exact path="/">
        <Content />
      </Route>
      <Redirect to="/" />
    </Switch>
  );
};

export default App;
