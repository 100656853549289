import React from 'react';
import { Pagination } from 'semantic-ui-react';

const AnnouncementNav = ({ index, total, setIndex }) => (
  <Pagination
    boundaryRange={0}
    activePage={index}
    ellipsisItem={null}
    firstItem={null}
    lastItem={null}
    pageItem={null}
    onPageChange={(_e, value) => { setIndex(value.activePage); }}
    siblingRange={0}
    totalPages={total}
    prevItem={index === 1 ? false : { content: '' }}
    nextItem={index === total ? false : { content: '' }}
    size="small"
  />
);
export default AnnouncementNav;
