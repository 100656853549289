/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useRef, useState } from 'react';
import {
  Container,
  Segment,
  Breadcrumb,
  Card,
  Form,
  Button,
  Message,
  Icon,
  Ref,
  Divider,
  Modal,
} from 'semantic-ui-react';
import axios from 'axios';

import {
  API_BASE,
  API_ENDPOINT_REQUEST_WORKSPACE,
  LINK_ARIDHIA_CONTACT_SD,
  LINK_ADDI_CURATION_TERMS_OF_USE,
  LINK_ARIDHIA_KB_VM_REQUEST,
  LINK_MICROSOFT_DOC_VM_SIZES,
} from 'env-create-react-app';

import { msalObj } from '../auth/signin';

function exampleReducer(state, action) {
  switch (action.type) {
    case 'OPEN_MODAL':
      return { open: true, dimmer: action.dimmer };
    case 'CLOSE_MODAL':
      return { open: false };
    default:
      throw new Error();
  }
}

const RequestWorkspace = () => {
  const [state, dispatch] = React.useReducer(exampleReducer, {
    open: false,
    dimmer: undefined,
  });
  const { open, dimmer } = state;
  const valuesOrig = {
    vm_linux: false,
    vm_windows: false,
    acceptToC: false,
    interest_1: false,
    interest_2: false,
    interest_3: false,
    interest_4: false,
    interest_other: false,
  };

  const errorsOrig = {
    project_name: false,
    project_name_message: 'Please provide a name for your project',
    project_description: false,
    project_description_message: 'Please provide a description of your project',
    workspace_hub: false,
    workspace_hub_message: 'Please select a hub location for your workspace',
    interest: false,
    interest_message: 'Please select at least one of the options',
    focusarea: false,
    focusarea_message: 'Please select one of the options',
    apply_data_science_template: false,
    apply_data_science_template_message: 'Please select one of the options',
  };

  const [errors, setErrors] = useState(errorsOrig);

  const [values, setValues] = useState(valuesOrig);

  const [submitDisabled, setSubmitDisabled] = useState(false);

  const formRef = useRef(null);

  const [message, setMessage] = useState({
    showMessage: false,
    header: '',
    message: '',
    isLoading: '',
    isError: false,
    isSuccess: false,
  });

  const onChange = (event, result) => {
    const { name, value } = result || event.target;
    setValues({ ...values, [name]: value });
    setErrors({ ...errors, [name]: false }); // set error to false after any value change
  };

  const onChangeCheckbox = (event, result) => {
    const { name, checked } = result || event.target;
    setValues({ ...values, [name]: checked });
    setErrors({ ...errors, [name]: false }); // set error to false after any value change
  };

  const onChangeInterest = (event, result) => {
    const { name, checked, value } = result || event.target;
    if (name === 'interest_other_text') {
      setValues({ ...values, [name]: value });
    } else {
      setValues({ ...values, [name]: checked });
    }
    setErrors({ ...errors, interest: false }); // set error to false after any value change
  };

  const onChangeFocusArea = (event, result) => {
    const { name, value } = result || event.target;
    if (name === 'focusarea_other_text') {
      setValues({ ...values, [name]: value });
    } else {
      setValues({ ...values, focusarea: value });
    }
    setErrors({ ...errors, focusarea: false }); // set error to false after any value change
  };

  const hideMessage = (clearFields = true) => {
    setMessage({ showMessage: false });
    if (clearFields) {
      setValues(valuesOrig);
      setErrors(errorsOrig);
      formRef.current.reset();
    }
    setSubmitDisabled(false);
  };

  const handleSuccessMessage = () => {
    setMessage({
      showMessage: true,
      header: 'Message Sent',
      message:
        'Thank you for making the request which is now under review. You will be provided an invitation to your workspace on approval.',
      isLoading: false,
      isError: false,
      isFormError: false,
      isSuccess: true,
    });
  };

  const handlefailIdTokenMessage = () => {
    setMessage({
      showMessage: true,
      header: 'Failed to retrieve ID Token',
      message: 'There was an error processing your request. (Authentication error).',
      isLoading: false,
      isError: false,
      isFormError: false,
      isSuccess: true,
    });
    setSubmitDisabled(false);
  };

  const handleFailUnauthorizedMessage = () => {
    setMessage({
      showMessage: true,
      header: 'Unauthorized',
      message: 'Your token was unauthorized. Please try to log out and log back in again.',
      isLoading: false,
      isError: true,
      isFormError: false,
      isSuccess: false,
    });
    setSubmitDisabled(false);
  };

  const handleGenericErrorMessage = () => {
    setMessage({
      showMessage: true,
      header: 'Error',
      message: 'Unfortunately there was an error processing your request.',
      isLoading: false,
      isError: true,
      isFormError: false,
      isSuccess: false,
    });
    setSubmitDisabled(false);
  };

  const handleFormErrorMessage = () => {
    setMessage({
      showMessage: true,
      header: 'Please check the errors in the form',
      message: 'Please review and address the errors displayed above and resubmit your form.',
      isLoading: false,
      isError: false,
      isFormError: true,
      isSuccess: false,
    });
    setSubmitDisabled(false);
  };

  const handleToCErrorMessage = () => {
    setMessage({
      showMessage: true,
      header: 'Please accept the Terms and Conditions',
      message: 'In order to submit this request you must read and accept the terms and conditions.',
      isLoading: false,
      isError: false,
      isFormError: true,
      isSuccess: false,
    });
    setSubmitDisabled(false);
  };

  const displayMessageContent = () => {
    if (message.isLoading) {
      return (
        <Message icon>
          <Icon name="circle notched" loading />
          <Message.Content>
            <Message.Header>{message.header}</Message.Header>
            <p>{message.message}</p>
          </Message.Content>
        </Message>
      );
    }

    if (message.isError) {
      return (
        <Message negative onDismiss={() => hideMessage(false)}>
          <Message.Header>{message.header}</Message.Header>
          <p>{message.message}</p>
          <p>
            If you keep seeing this message, please
            {' '}
            <a href={LINK_ARIDHIA_CONTACT_SD} target="_blank" rel="noreferrer">
              contact the service desk
            </a>
            .
          </p>
        </Message>
      );
    }

    if (message.isFormError) {
      return (
        <Message negative onDismiss={() => hideMessage(false)}>
          <Message.Header>{message.header}</Message.Header>
          <p>{message.message}</p>
        </Message>
      );
    }

    if (message.isSuccess) {
      return (
        <Message positive onDismiss={() => hideMessage(true)}>
          <Message.Header>{message.header}</Message.Header>
          <p>{message.message}</p>
        </Message>
      );
    }

    return (
      <Message onDismiss={() => hideMessage(false)}>
        <Message.Header>{message.header}</Message.Header>
        <p>{message.message}</p>
      </Message>
    );
  };

  const onSubmit = (event) => {
    setSubmitDisabled(true);

    // parse interest form field
    let interest = '';
    if (values.interest_1) interest += 'Data Science - general, ';
    if (values.interest_2) interest += 'Bioinformatics/Biostatistics, ';
    if (values.interest_3) interest += 'Mathematics/Statistics, ';
    if (values.interest_4) interest += 'AI/Machine Learning, ';
    if (values.interest_other && values.interest_other_text) {
      interest += `Other: ${values.interest_other_text}, `;
    }
    interest = interest.replace(/,\s$/, '');

    // parse focusarea form field
    let focusarea = '';
    if (values.focusarea === 'focusarea1') focusarea = 'Life science - general';
    else if (values.focusarea === 'focusarea2') focusarea = 'Neuroscience - general';
    else if (values.focusarea === 'focusarea3') focusarea = 'Neurodegenerative diseases - general';
    else if (values.focusarea === 'focusarea4') {
      focusarea = "Alzheimer's disease and related dementias";
    } else if (values.focusarea === 'focusarea_other') {
      focusarea = `Other: ${values.focusarea_other_text}`;
    }

    // Form validation
    let formValid = true;
    let scrollToElement = '';
    const errorsUpdate = {};

    if (!values.project_name) {
      formValid = false;
      errorsUpdate.project_name = true;
      if (!scrollToElement) scrollToElement = 'form-input-project_name';
    }
    if (!values.project_description) {
      formValid = false;
      errorsUpdate.project_description = true;
      if (!scrollToElement) scrollToElement = 'form-input-project_description';
    }
    if (!values.workspace_hub) {
      formValid = false;
      errorsUpdate.workspace_hub = true;
      if (!scrollToElement) scrollToElement = 'form-input-workspace_hub';
    }
    if (!interest) {
      formValid = false;
      errorsUpdate.interest = true;
      if (!scrollToElement) scrollToElement = 'form-input-interest';
    }
    if (!focusarea) {
      formValid = false;
      errorsUpdate.focusarea = true;
      if (!scrollToElement) scrollToElement = 'form-input-focusarea';
    }

    if (!values.apply_data_science_template && (values.vm_linux || values.vm_windows)) {
      formValid = false;
      errorsUpdate.apply_data_science_template = true;
      if (!scrollToElement) scrollToElement = 'form-input-datasciencevm';
    }
    if (!values.apply_data_science_template && !values.vm_linux && !values.vm_windows) {
      values.apply_data_science_template = 'No VM was selected.';
    }

    if (!formValid) {
      setErrors({ ...errors, ...errorsUpdate }); // set all errors at once
      handleFormErrorMessage();
      document
        .getElementById(scrollToElement)
        .scrollIntoView({ block: 'start', inline: 'nearest', behavior: 'smooth' });
      return;
    }

    if (!values.acceptToC) {
      handleToCErrorMessage();
      return;
    }

    setMessage({
      showMessage: true,
      header: 'Just one second',
      message: 'We are submitting your form.',
      isLoading: true,
      isError: false,
      isSuccess: false,
    });

    const account = msalObj.getAccount();
    if (typeof account.idToken === 'undefined' || account.idToken == null) {
      console.error('Failed to obtain idToken');
      handlefailIdTokenMessage();
    } else {
      // Information on the logged in account
      const givenName = account.idToken.given_name;
      const familyName = account.idToken.family_name;
      const email = account.idToken.emails && account.idToken.emails.length > 0
        ? account.idToken.emails[0]
        : 'Not Available';
      const userid = account.idToken.oid ? account.idToken.oid : 'Not Available';

      let data = values;

      data.timestamp = `${new Date().toISOString()}`;
      data.source_url = window.location.href;
      data.userid = userid;
      data.email = email;
      data.full_name = `${givenName} ${familyName}`;
      data.interest = interest;
      data.focusarea = focusarea;
      data = JSON.stringify(data);
      axios
        .post(
          `${API_BASE}${API_ENDPOINT_REQUEST_WORKSPACE}`,

          data,
          {
            headers: {
              Authorization: `Bearer ${window.sessionStorage.getItem('msal.idtoken')}`,
              'Content-Type': 'application/json',
            },
          },
        )
        .then(() => {
          handleSuccessMessage();
        })
        .catch((error) => {
          if (error.response) {
            // Request made and server responded
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
            if (error.response.status === 401) {
              handleFailUnauthorizedMessage();
            } else {
              handleGenericErrorMessage();
            }
          } else if (error.request) {
            // The request was made but no response was received
            console.log(error.request);
            handleGenericErrorMessage();
          } else {
            // Something happened in setting up the request that triggered an Error
            console.log('Error', error.message);
            handleGenericErrorMessage();
          }
        });
      event.preventDefault();
    }
  };

  return (
    <div className="portal page extra content">
      <Container>
        <Segment className="portal-elements">
          <Breadcrumb>
            <Breadcrumb.Section href="/">Home</Breadcrumb.Section>
            <Breadcrumb.Divider icon="right angle" />
            <Breadcrumb.Section active>Request a Workspace</Breadcrumb.Section>
          </Breadcrumb>
        </Segment>
        <Card fluid>
          <Card.Header className="content">
            <Icon name="exclamation circle" size="large" className="right floated blue" />
            <span className="workspace-name">Request a Workspace</span>
          </Card.Header>
          <Card.Content>
            <Card.Description>
              <p>
                This form can be used to request a workspace. Response will be via email to your
                registered user account.
              </p>
              <p>Please allow 24 hours for your request to be processed.</p>
            </Card.Description>
          </Card.Content>
          <Card.Content>
            <Ref innerRef={formRef}>
              <Form id="form-request-workspace" onSubmit={onSubmit}>
                <Form.Field
                  required
                  id="form-input-project_name" // allows hook to scroll to in case of error
                  style={{ scrollMarginTop: '5rem' }} // offset scroll to accomodate header
                >
                  <label>Project Name</label>
                  <Form.Input
                    label=""
                    name="project_name"
                    placeholder="Project Name"
                    onChange={onChange}
                    error={
                      errors.project_name
                        ? { content: errors.project_name_message, pointing: 'above' }
                        : false
                    }
                  />
                </Form.Field>
                <Form.Field
                  required
                  id="form-input-project_description" // allows hook to scroll to in case of error
                  style={{ scrollMarginTop: '5rem' }} // offset scroll to accomodate header
                >
                  <label>Project Description</label>
                  <Form.TextArea
                    label=""
                    name="project_description"
                    placeholder="Please describe your project"
                    onChange={onChange}
                    rows="3"
                    error={
                      errors.project_description
                        ? { content: errors.project_description_message, pointing: 'above' }
                        : false
                    }
                  />
                </Form.Field>
                <Form.Field>
                  <Form.TextArea
                    label="Additional Users"
                    name="additional_users"
                    placeholder="Please provide email addresses of other users who should be invited to the workspace"
                    onChange={onChange}
                    rows="3"
                  />
                </Form.Field>
                <Form.Field
                  required
                  id="form-input-workspace_hub" // allows hook to scroll to in case of error
                  style={{ scrollMarginTop: '5rem' }} // offset scroll to accomodate header
                >
                  <label>What hub do you need your workspace to be located?</label>
                  <Form.Select
                    label=""
                    name="workspace_hub"
                    onChange={onChange}
                    error={
                      errors.workspace_hub
                        ? { content: errors.workspace_hub_message, pointing: 'above' }
                        : false
                    }
                    placeholder="Select Hub location..."
                    options={[
                      {
                        key: 'useast',
                        text: 'US East',
                        value: 'useast',
                      },
                      {
                        key: 'northeurope',
                        text: 'North Europe',
                        value: 'northeurope',
                      },
                    ]}
                  />
                </Form.Field>
                <Divider />
                <Form.Group className="grouped">
                  <h5>
                    Do you require a virtual machine?
                    {' '}
                    <a
                      href="#/"
                      className="underline"
                      onClick={() => dispatch({ type: 'OPEN_MODAL', dimmer: 'blurring' })}
                    >
                      Find out more
                    </a>
                  </h5>
                  <div>
                    <Modal
                      dimmer={dimmer}
                      open={open}
                      onClose={() => dispatch({ type: 'CLOSE_MODAL' })}
                    >
                      <Modal.Header>ADWB Standard Free Workspace Offering</Modal.Header>
                      <Modal.Content>
                        <h5>Workspaces VMs:</h5>
                        <ul>
                          <li>
                            Workspaces may have multiple virtual machines (VM).
                          </li>
                          <li>
                            Each VM has 4 vCPUs, 16 GB memory, and 28 GB temp storage.
                          </li>
                          <li>
                            VM's are automatically shut down each day at 19:00 based on the region.
                          </li>
                        </ul>
                        <h5>Workspace Shared Storage:</h5>
                        <ul>
                          <li>
                            Each workspace has a 5 TB shared drive that is accessible by all VMs.
                          </li>
                        </ul>
                        If you require any changes to the standard configurations,
                        {' '}
                        like additional VMs or more powerful/upgraded VMs or
                        {' '}
                        additional storage please e-mail
                        {' '}
                        <a
                          href="mailto:servicedesk@aridhia.com"
                          className="underline"
                        >
                          servicedesk@aridhia.com
                        </a>
                        {' '}
                        specifying the name of your workspace, and provide specific details of your
                        {' '}
                        needs.
                        <br />
                        <br />
                        Details around available VM configurations can be found on
                        {' '}
                        <a
                          href={LINK_MICROSOFT_DOC_VM_SIZES}
                          target="blank"
                          className="underline"
                        >
                          Microsoft Docs
                        </a>
                        {' '}
                        and the
                        {' '}
                        <a
                          href={LINK_ARIDHIA_KB_VM_REQUEST}
                          target="blank"
                          className="underline"
                        >
                          Aridhia Knowledge Base
                        </a>
                        .
                      </Modal.Content>
                      <Modal.Actions>
                        <Button color="black" onClick={() => dispatch({ type: 'CLOSE_MODAL' })}>
                          Close
                        </Button>
                      </Modal.Actions>
                    </Modal>
                  </div>
                  <Form.Field>
                    <Form.Checkbox
                      name="vm_windows"
                      label="Windows Virtual Machine"
                      onClick={onChangeCheckbox}
                      checked={values.vm_windows}
                    />
                  </Form.Field>
                  <Form.Field>
                    <Form.Checkbox
                      name="vm_linux"
                      label="Linux Virtual Machine"
                      onClick={onChangeCheckbox}
                      checked={values.vm_linux}
                    />
                  </Form.Field>
                </Form.Group>

                {values.vm_linux || values.vm_windows ? (
                  <Form.Group className="grouped">
                    <Form.Field // form input allows for error tag to be grouped
                      id="form-input-datasciencevm" // allows hook to scroll to in case of error
                      style={{ scrollMarginTop: '5rem' }} // offset scroll to accomodate header>
                    >
                      <label>
                        <p>
                          Do you a require a Data Science template to be applied to your Virtual
                          Machine? The Data Science template includes the most common tools used in
                          Data Science Workflows i.e.
                          <span className="required">*</span>
                        </p>
                      </label>
                      <ul>
                        <li>
                          <a href="https://jupyter.org/" target="_blank" rel="noreferrer">
                            Jupyter Notebook
                          </a>
                        </li>
                        <li>
                          <a href="https://www.rstudio.com/" target="_blank" rel="noreferrer">
                            RStudio
                          </a>
                        </li>
                        <li>
                          <a href="https://code.visualstudio.com/" target="_blank" rel="noreferrer">
                            Visual Studio Code
                          </a>
                        </li>
                        <li>
                          <a href="https://julialang.org/" target="_blank" rel="noreferrer">
                            Julia
                          </a>
                        </li>
                        <li>
                          <a href="https://cran.r-project.org/" target="_blank" rel="noreferrer">
                            CRAN-R
                          </a>
                        </li>
                      </ul>
                      <p>
                        For more information please reference our Knowledge Base article
                        {' '}
                        <a href="https://knowledgebase.aridhia.io/article/using-a-data-science-virtual-machine/" target="_blank" rel="noreferrer">Using a Data Science Virtual Machine – Aridhia DRE Workspaces Knowledge Base</a>
                      </p>
                      <Form.Input
                        required
                        className="form-input-stack-items"
                        error={
                          errors.apply_data_science_template
                            ? {
                              content: errors.apply_data_science_template_message,
                              pointing: 'above',
                            }
                            : false
                        }
                        input={
                          <>
                            <Form.Radio
                              label="Yes, apply the Data Science template to the virtual machine."
                              name="apply_data_science_template"
                              value="Yes"
                              checked={values.apply_data_science_template === 'Yes'}
                              onChange={onChange}
                            />
                            <Form.Radio
                              label="No, I don't want tools pre-installed on the virtual machine."
                              name="apply_data_science_template"
                              value="No"
                              checked={values.apply_data_science_template === 'No'}
                              onChange={onChange}
                            />
                          </>
                        }
                      />
                    </Form.Field>
                  </Form.Group>
                ) : (
                  ''
                )}

                <Divider />

                <Form.Group className="grouped">
                  <Form.Field
                    id="form-input-interest" // allows hook to scroll to in case of error
                    style={{ scrollMarginTop: '5rem' }} // offset scroll to accomodate header>
                  >
                    <Form.Input // form input allows for error tag to be grouped
                      label="What is your area of interest? Check all that apply."
                      required
                      className="form-input-stack-items"
                      error={
                        errors.interest
                          ? { content: errors.interest_message, pointing: 'above' }
                          : false
                      }
                      input={
                        <>
                          <Form.Checkbox
                            name="interest_1"
                            label="Data Science - general"
                            onClick={onChangeInterest}
                            checked={values.interest_1}
                          />
                          <Form.Checkbox
                            name="interest_2"
                            label="Bioinformatics/Biostatistics"
                            onClick={onChangeInterest}
                            checked={values.interest_2}
                          />
                          <Form.Checkbox
                            name="interest_3"
                            label="Mathematics/Statistics"
                            onClick={onChangeInterest}
                            checked={values.interest_3}
                          />
                          <Form.Checkbox
                            name="interest_4"
                            label="AI/Machine Learning"
                            onClick={onChangeInterest}
                            checked={values.interest_5}
                          />
                          <Form.Checkbox
                            name="interest_other"
                            label="Other"
                            onClick={onChangeInterest}
                            checked={values.interest_other}
                          />
                          <Form.Input
                            label=""
                            className={values.interest_other ? '' : 'hidden'}
                            name="interest_other_text"
                            placeholder="Other - Please Specify"
                            onChange={onChangeInterest}
                          />
                        </>
                      }
                    />
                  </Form.Field>
                </Form.Group>
                <Form.Group className="grouped">
                  <Form.Field // form input allows for error tag to be grouped
                    id="form-input-focusarea" // allows hook to scroll to in case of error
                    style={{ scrollMarginTop: '5rem' }} // offset scroll to accomodate header>
                  >
                    <Form.Input
                      label="What is your area of focus?"
                      required
                      className="form-input-stack-items"
                      error={
                        errors.focusarea
                          ? { content: errors.focusarea_message, pointing: 'above' }
                          : false
                      }
                      input={
                        <>
                          <Form.Radio
                            label="Life science - general"
                            name="focusarea"
                            value="focusarea1"
                            checked={values.focusarea === 'focusarea1'}
                            onChange={onChangeFocusArea}
                          />
                          <Form.Radio
                            label="Neuroscience - general"
                            name="focusarea"
                            value="focusarea2"
                            checked={values.focusarea === 'focusarea2'}
                            onChange={onChangeFocusArea}
                          />
                          <Form.Radio
                            label="Neurodegenerative diseases - general"
                            name="focusarea"
                            value="focusarea3"
                            checked={values.focusarea === 'focusarea3'}
                            onChange={onChangeFocusArea}
                          />
                          <Form.Radio
                            label="Alzheimer's disease and related dementias"
                            name="focusarea"
                            value="focusarea4"
                            checked={values.focusarea === 'focusarea4'}
                            onChange={onChangeFocusArea}
                          />
                          <Form.Radio
                            label="Other"
                            name="focusarea"
                            value="focusarea_other"
                            checked={values.focusarea === 'focusarea_other'}
                            onChange={onChangeFocusArea}
                          />
                          <Form.Input
                            label=""
                            className={values.focusarea === 'focusarea_other' ? '' : 'hidden'}
                            name="focusarea_other_text"
                            placeholder="Other - Please Specify"
                            onChange={onChangeFocusArea}
                          />
                        </>
                      }
                    />
                  </Form.Field>
                </Form.Group>

                <Divider />

                <Form.Field>
                  <Form.TextArea
                    label="Any other requirements?"
                    name="other_requirements"
                    placeholder="Please provide any additional details to help us set up your workspace"
                    onChange={onChange}
                    rows="3"
                  />
                </Form.Field>
                <Divider />
                <p>
                  Before submitting the request, please make sure you have read and agreed to the
                  {' '}
                  <a href={LINK_ADDI_CURATION_TERMS_OF_USE} target="_blank" rel="noreferrer">
                    Terms and Conditions
                  </a>
                  .
                </p>

                <Form.Field>
                  <Form.Checkbox
                    name="acceptToC"
                    label="Agree to Terms and Conditions"
                    onClick={onChangeCheckbox}
                    checked={values.acceptToC}
                  />
                </Form.Field>
                <Form.Field>{message.showMessage ? displayMessageContent() : ''}</Form.Field>
                <div>
                  <Button
                    id="form-request-workspace-submit"
                    type="submit"
                    disabled={submitDisabled}
                  >
                    Submit
                  </Button>
                </div>
              </Form>
            </Ref>
          </Card.Content>
        </Card>
      </Container>
    </div>
  );
};

export default RequestWorkspace;
