/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import {
  Container, Segment, Grid, Header, Card, Icon, Divider,
} from 'semantic-ui-react';
import {
  LINK_ADDI_WORKSPACE_CURATION_HUB,
  LINK_ADDI_PORTAL_PAGE,
  LINK_ARIDHIA_KB_WORKSPACES,
  LINK_ARIDHIA_CONTACT_SD,
  LINK_ADDI_ALZHEIMERS_DATA,
  LINK_ADDI_CURATION_MAILTO,
  LINK_ARIDHIA_SERVICE_DESCRIPTION,
  LINK_ARIDHIA_SECURITY_AND_COMPLIANCE,
  ENDPOINT_REQUEST_WORKSPACE,
} from 'env-create-react-app';

import workspacesLogo2020Coloured from '../images/workspaces-logo-colour.svg';
import poweredByAridhia from '../images/AridhiaDRE-PoweredBy.svg';
import dataPrepDoc from '../docs/ADDI_Data_Prep_Guide_2022.pdf';
import dataPrepTemplate from '../docs/Data_Prep_Templates.xlsx';

const Content = () => (
  <div className="portal page content">
    <Container>
      <Segment className="portal-elements">
        <Header as="h2">Getting Started</Header>
        <Grid stackable columns="equal">
          <Grid.Column>
            <Card fluid>
              <Card.Header className="content">
                <Icon name="info circle" size="large" className="right floated blue" />
                <a className="workspace-name" target="_blank" rel="noreferrer" href={LINK_ADDI_WORKSPACE_CURATION_HUB}>Access the Alzheimer's Disease Curation Studio</a>
              </Card.Header>
              <Card.Content className="hero-content">
                <Card.Description className="hero-child">
                  <p>
                    AD Curation studio provides a secured environment where data contributors
                    can easily upload and curate their data in order to share it with the
                    research community via AD Workbench.
                  </p>
                  <p>
                    To satisfy the requirement of sharing data via AD Workbench, please review this
                    {' '}
                    <a href={dataPrepDoc} download="ADDI_Data_Prep_Guide_2022.pdf">document</a>
                    {' '}
                    and this
                    {' '}
                    <a href={dataPrepTemplate} download="ADDI_Data_Prep_Templates.xlsx">template</a>
                    .
                  </p>
                </Card.Description>
              </Card.Content>
            </Card>
          </Grid.Column>
        </Grid>
        <Divider />
        <Grid stackable columns="equal">
          <Grid.Column>
            <Card fluid>
              <Card.Header className="content">
                <img src={workspacesLogo2020Coloured} alt="Workspaces Logo" aria-hidden="true" className="right floated icon" />
                <a className="workspace-name" target="_blank" rel="noreferrer" href={LINK_ADDI_WORKSPACE_CURATION_HUB}>Curation Hub</a>
              </Card.Header>
              <Card.Content>
                <Card.Description className="content">
                  <p>
                    Follow the link below to access your workspaces in the AD Curation Hub:
                  </p>
                  <a href={LINK_ADDI_WORKSPACE_CURATION_HUB} className="ui purple button" target="_blank" rel="noreferrer">Go to Curation Hub</a>
                </Card.Description>
              </Card.Content>
            </Card>
          </Grid.Column>
          <Grid.Column>
            <Card fluid>
              <Card.Header className="content">
                <img src={workspacesLogo2020Coloured} alt="Workspaces Logo" aria-hidden="true" className="right floated icon" />
                <a className="workspace-name" href={ENDPOINT_REQUEST_WORKSPACE}>Request a Workspace</a>
              </Card.Header>
              <Card.Content>
                <Card.Description>
                  <p>
                    Follow the link below to request a Curation Workspace:
                  </p>
                  <a href={ENDPOINT_REQUEST_WORKSPACE} className="ui purple button">Request a workspace</a>
                </Card.Description>
              </Card.Content>
            </Card>
          </Grid.Column>
        </Grid>
        <Grid stackable columns="equal">
          <Grid.Column>
            <Card fluid>
              <Card.Header className="content">
                <Icon name="question circle outline" size="large" className="right floated blue" />
                <a className="workspace-name" target="_blank" rel="noreferrer" href={LINK_ARIDHIA_KB_WORKSPACES}>Knowledge Base</a>
              </Card.Header>
              <Card.Content>
                <Card.Description>
                  <p>
                    Useful information and 'how to' guides on the Curation Studio Workspaces can be
                    found in the
                    {' '}
                    <a href={LINK_ARIDHIA_KB_WORKSPACES} target="_blank" rel="noreferrer">Knowledge Base</a>
                    .
                  </p>
                </Card.Description>
              </Card.Content>
            </Card>
          </Grid.Column>
          <Grid.Column>
            <Card fluid>
              <Card.Header className="content">
                <Icon name="phone" size="large" className="right floated blue" />
                <a className="workspace-name" target="_blank" rel="noreferrer" href={LINK_ARIDHIA_CONTACT_SD}>Need Help?</a>
              </Card.Header>
              <Card.Content>
                <Card.Description>
                  <p>
                    If you have any questions or run into any problems, please reach out to the
                    {' '}
                    <a target="_blank" rel="noreferrer" href={LINK_ARIDHIA_CONTACT_SD}>Service Desk team</a>
                    .
                  </p>
                </Card.Description>
              </Card.Content>
            </Card>
          </Grid.Column>
        </Grid>

        <Divider />

        <Header as="h2">Further Information</Header>
        <Grid stackable columns="equal">
          <Grid.Column>
            <Card fluid>
              <Card.Header className="content">
                <Icon name="info circle" size="large" className="right floated blue" />
                <a className="workspace-name" target="_blank" rel="noreferrer" href={LINK_ADDI_ALZHEIMERS_DATA}>The Alzheimer's Disease Data Initiative</a>
              </Card.Header>
              <Card.Content className="hero-content">
                <Card.Description className="hero-child">
                  <p>
                    The
                    {' '}
                    <a href={LINK_ADDI_ALZHEIMERS_DATA} target="_blank" rel="noreferrer">Alzheimer's Disease Data Initiative's (AD Data Initiative)</a>
                    {' '}
                    mission is to fundamentally transform Alzheimer's Disease (AD) research. By
                    providing infrastructure and data science tools, funding data sharing
                    opportunities and fostering global collaborations, AD Data Initiative is
                    advancing scientific breakthroughs and accelerating progress towards new
                    treatments and cures for AD and related dementias (ADRD).
                  </p>
                  <p>
                    AD Data Initiative is actively working to lower the barriers to entry for data
                    contributors to share data and for users to access the data. AD Data Initiative
                    supports this by providing secure workspaces to conduct analysis, creating user
                    friendly data analysis workflows, and establishing trusted data governance
                    processes. The focus is to build a strong global research community, enable
                    data democratization and promote open science.
                  </p>
                </Card.Description>
              </Card.Content>
            </Card>
          </Grid.Column>
        </Grid>
        <Grid stackable columns="equal">
          <Grid.Column>
            <Card fluid>
              <Card.Header className="content">
                <Icon name="info circle" size="large" className="right floated blue" />
                <a className="workspace-name" target="_blank" rel="noreferrer" href={LINK_ADDI_PORTAL_PAGE}>AD Workbench</a>
              </Card.Header>
              <Card.Content className="hero-content">
                <Card.Description className="hero-child">
                  <p>
                    AD Data Initiative has created a cloud based global data platform called the
                    {' '}
                    <a href={LINK_ADDI_PORTAL_PAGE} target="_blank" rel="noreferrer">Alzheimer's Disease Workbench (ADWB)</a>
                    {' '}
                    which allows researchers around the world to share data, resources,
                    and tools in a secured manner. AD Data Initiative offers storage and
                    compute services where infrastructure and resources might limit the
                    ability to conduct research. AD Workbench actively supports data
                    harmonization, curation, and quality check mechanisms on datasets
                    across different data types.
                  </p>
                </Card.Description>
              </Card.Content>
            </Card>
          </Grid.Column>
        </Grid>
        <Grid stackable columns="equal">
          <Grid.Column>
            <Card fluid>
              <Card.Header className="content">
                <Icon name="info circle" size="large" className="right floated blue" />
                <a className="workspace-name" href="/">AD Curation Studio</a>
              </Card.Header>
              <Card.Content className="hero-content">
                <Card.Description className="hero-child">
                  <p>
                    Several potential dataset providers have communicated a lack of technical
                    infrastructure and resources, at their end, to store and curate data have
                    prevented data publication for research via the ADWB. To remove this barrier and
                    support data storage and curation on the AD Workbench, AD Data Initiative offers
                    AD Curation Studio. This is a secured environment where data contributors can
                    easily upload and curate their data, which will then be available to the
                    research community through the AD Workbench. Just like the AD Workbench, the AD
                    Curation Studio provides secured workspaces where researchers can store and
                    analyze data at no-cost. The AD Curation Studio’s aim is to accelerate the
                    preparation of datasets that will be published on the AD Workbench.
                  </p>
                </Card.Description>
              </Card.Content>
            </Card>
          </Grid.Column>
        </Grid>
        <Grid stackable columns="equal">
          <Grid.Column>
            <Card fluid>
              <Card.Header className="content">
                <Icon name="info circle" size="large" className="right floated blue" />
                <a className="workspace-name" href="/">AD Curation Studio Features</a>
              </Card.Header>
              <Card.Content className="hero-content">
                <Card.Description className="hero-child">
                  <h4>1. ACCESS</h4>
                  <p>
                    While the AD Curation Studio is a part of the AD Data Initiative product suite,
                    it is a distinct and independent product from the AD Workbench. If users
                    already have an account with the AD Workbench, they can sign the Terms &
                    Conditions for the AD Curation Studio and use the same username and password.
                  </p>
                  <p>
                    Access to AD Curation Studio is by invitation only and will be approved by the
                    AD Data Initiative administration team.
                  </p>
                  <p>
                    For inquiries or to request access to the AD Curation Studio, please reach out
                    to
                    {' '}
                    <a href={LINK_ADDI_CURATION_MAILTO} target="_blank" rel="noreferrer">AD Curation Studio</a>
                    {' '}
                    .
                  </p>
                  <h4>2. DATA STORAGE AND INGEST</h4>
                  <p>
                    Data storage and workspace functionality is supported by Aridhia Digital
                    Research Environment (DRE). It supports raw, structured, and unstructured
                    data of different types and storage size. Data as well as approved curation
                    tools are ingested into assigned workspaces.
                  </p>
                  <h4>3. WORKSPACES</h4>
                  <p>
                    AD Curation Studio workspaces are distinct and independent of AD Workbench
                    research workspaces. A separate workspace access request form will be provided.
                    The AD Workbench FAIR data services portal is not linked to AD Curation Studio
                    and the product exists to support data curation and preparation for migration to
                    the ADWB for sharing with the ADWB community for research. Users can upload data
                    sets into curation workspace. However, curation workspaces should not be used to
                    conduct research analysis projects. Once the data curation process is finished,
                    requirements around publication to ADWB need to be fulfilled (e.g., metadata,
                    data dictionary, and indexing etc.)
                  </p>
                  <p>
                    Please read the detailed service description on workspaces
                    {' '}
                    <a href={LINK_ARIDHIA_SERVICE_DESCRIPTION} target="_blank" rel="noreferrer">here</a>
                    .
                  </p>
                  <h4>4. SECURITY</h4>
                  <p>
                    The workspaces have been certified as meeting HIPAA and GDPR security
                    requirements (HITRUST certified).  Regional data restriction requirements are
                    maintained by spinning up-servers across different regions of the world. More
                    information on security measures & certifications can be found
                    {' '}
                    <a href={LINK_ARIDHIA_SECURITY_AND_COMPLIANCE} target="_blank" rel="noreferrer">here</a>
                    .
                  </p>
                </Card.Description>
              </Card.Content>
            </Card>
          </Grid.Column>
        </Grid>
      </Segment>
      <Segment className="portal-elements">
        <img src={poweredByAridhia} alt="Powered by Aridhia DRE" className="powered-by-aridhia" />
      </Segment>
    </Container>
  </div>
);

export default Content;
